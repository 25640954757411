<template>
	<div class="page webclass_page">
		<div class="class_topbox" v-if="isLogin">
			<img class="bgimg" src="../../assets/static/Rectangle18.png"/>
			<div class="topcon w1200">
				<div class="img"><img class="img100" :src="classObj.imagesUrl"/></div>
				<div class="titlebox">
					<div class="text1 fontsize32 clamp2">{{classObj.title}}</div>
					<div class="textcon fontsize16">
						<div class="text2">难度：{{difficultyList[classObj.difficulty]}}</div>
						<div class="text2 ml20">时长：{{classObj.longTime}}</div>
						<div class="text2 ml20">学习人数：{{classObj.memNum}}</div>
					</div>
				</div>
				<div class="ml-auto pricebox fontsize24" v-if="!classObj.trainUserSchedule">
					<div class="free" v-if="classObj.allOpen==0">免费</div>
					<div class="nofree" v-if="classObj.allOpen==1">￥{{classObj.schedulePrice}}</div>
				</div>
				<div class="ml-auto pricebox fontsize24" v-if="classObj.trainUserSchedule">
					<div class="progresstext fontsize16">进度{{progresstext}}%</div>
				</div>
				<div class="btn1" v-if="!classObj.trainUserSchedule" @click="setUserAndSchedule">加入学习</div>
				<div class="btn1" v-if="classObj.trainUserSchedule">{{studystate[classObj.trainUserSchedule.state]}}</div>
			</div>
		</div>
		<div class="class_comnbox w1200" v-if="isLogin">
			<div class="leftbox">
				<div class="typebox fontsize16">
					<div class="itemobj" :class="{'active':typeindex==1}" @click="handleType(1)">
						<div class="text">课程章节</div>
						<div class="line"></div>
					</div>
					<div class="itemobj" :class="{'active':typeindex==0}" @click="handleType(0)">
						<div class="text">课程介绍</div>
						<div class="line"></div>
					</div>
					<!-- <div class="itemobj" :class="{'active':typeindex==2}" @click="handleType(2)">
						<div class="text">用户评价</div>
						<div class="line"></div>
					</div> -->
				</div>
				<div class="typebox1" v-if="typeindex==0">
					<div class="typediv fontsize20">
						<div class="line"></div>
						<div class="text">课程简介</div>
					</div>
					<div class="desctext fontsize16" v-html="classObj.introduction"></div>
					<div class="block20"></div>
					<div class="typediv fontsize20">
						<div class="line"></div>
						<div class="text">课程详情</div>
					</div>
					<div class="desctext fontsize16" v-html="classObj.contents"></div>
				</div>
				<div class="typebox2" v-if="typeindex==1">
					<div class="itemobj" v-for="(item,index) in chapterList" :key="index">
						<div class="titlebox">
							<div class="text1 fontsize20">{{item.title}}</div>
							<div class="text2 fontsize14" v-if="item.allOpen==0">免费</div>
							<div class="text3 fontsize14" v-if="item.allOpen==2">精品</div>
						</div>
						<div class="descbox fontsize14">{{item.trainChapter.introduction}}</div>
						<div class="block10"></div>
						<div class="listbox">
							<div class="itembox" @click="handelclassdetail(item,sonitem)" v-for="(sonitem,sonindex) in item.trainChapterCourseList" :key="sonindex">
								<img class="img" src="../../assets/static/Subtract.png">
								<div class="text1 fontsize16">{{index+1}}-{{sonindex+1}} {{sonitem.courseTitle}}</div>
								<!-- <div class="text2 fontsize14">最近学习</div> -->
							</div>
						</div>
					</div>
				</div>
				<div class="typebox3" v-if="typeindex==2">
					<div class="nolist fontsize16" v-if="commentlist.length<=0">暂无相关记录!</div>
					<div class="itemobj" v-for="(item,index) in commentlist" :key="index">
						<img class="img" src="../../assets/static/logo.png">
						<div class="text1 fontsize16">该课程的互动性和学习氛围都非常优秀，让我能够更加深入地了解学科知识和实用技能。</div>
						<div class="text2 fontsize14">2024-01-26</div>
					</div>
				</div>
			</div>
			<div class="rightbox">
				<div class="typediv fontsize20">
					<div class="line"></div>
					<div class="text">推荐课程</div>
				</div>
				<div class="datalist">
					<div class="itemobj" v-for="(item,index) in tjclassList" :key="index" v-if="item.id!=uid">
						<div class="img"><img class="img100" :src="item.imagesUrl"/></div>
						<div class="rightdiv">
							<div class="titlename fontsize14 clamp2">{{item.title}}</div>
							<div class="decsbox fontsize12">
								<div class="text1" v-if="item.allOpen>0">￥{{item.schedulePrice}}</div>
								<div class="text1" v-if="item.allOpen==0">免费</div>
								<div class="text2">{{difficultyList[item.difficulty]}}</div>
								<div class="text3">
									<img class="icon" src="../../assets/static/Frameuser.png"/>
									<div class="text">{{item.memNum}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		
		
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			uid:"",//目录课程ID
			classObj:{},//课程目录详情
			studystate:["未学习","进行中","已完成"],
			typeindex:1,//
			tjclassList:[],
			chapterList:[],//课程章节
			commentlist:[],//评价
			progresstext:0,//进度
		};
	},
	mounted: function() {
		if(!this.isLogin){
			this.$message.error("请先返回首页登录！")
		}
		if(this.$route.query&&this.$route.query.uid){
			this.uid = this.$route.query.uid
			//获取我目录详情
			this.getScheduleById(null,null)
			//获取我目录章节
			this.userGetScheduleChapter()
		}else{
			this.$message.error("参数丢失，请返回")
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole','difficultyList']),
	},
	methods: {
		//加入学习
		setUserAndSchedule(){
			var _this = this
			//收费，需要购买，
			this.$confirm("确定要将此课程加入学习？", '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				//加入学习
				_this.handleaddFuc(null,null)
			}).catch(() => {});
		},
		//如果假如了学习，但是没购买
		checkVipbuy(){
			if(this.classObj.trainUserSchedule){
				//收费，需要购买，
				if(this.classObj.trainUserSchedule.payStatus==0 && this.classObj.allOpen>0){
					this.$confirm("此课程章节需要vip才能观看，可点击购买后观看！", '提示', {
						confirmButtonText: '去购买',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$router.push({
							path: "/dopay",
							query:{
								uid:this.uid
							}
						});
					}).catch(() => {});
				}
			}
		},
		//加入学习
		handleaddFuc(item,sonitem){
			var _this = this
			var params = {}
			params["scheduleId"] = this.uid
			params["userUuid"] = this.userInfo.uuid
			_this.$http.post('TrainModule/train-user-schedule2/setUserAndSchedule', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					//刷新
					_this.$message.success("加入学习成功！")
					//获取我目录详情
					_this.getScheduleById(item,sonitem)
					//获取我目录章节
					_this.userGetScheduleChapter()
					
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//跳转课程详情
		handelclassdetail(item,sonitem){
			var _this = this
			if(this.classObj.trainUserSchedule){
				if(this.classObj.allOpen==0 || item.allOpen==0){
					//免费直接眺
					//跳转前，查询是不是更新了课程
					this.beforeclassDetail(item,sonitem)
				}else{
					//收费，需要购买，
					if(this.classObj.trainUserSchedule.payStatus==0){
						this.$confirm("此课程章节需要vip才能观看，可点击购买后观看！", '提示', {
							confirmButtonText: '去购买',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$router.push({
								path: "/dopay",
								query:{
									uid:this.uid,
									chapterId:item.chapterId
								}
							});
						}).catch(() => {});
					}else{
						//跳转前，查询是不是更新了课程
						this.beforeclassDetail(item,sonitem)
					}
				}
			}else{
				this.$confirm("请先加入学习后观看！", '提示', {
					confirmButtonText: '加入学习',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//加入学习
					_this.handleaddFuc(item,sonitem)
				}).catch(() => {});
			}
		},
		//跳转前，查询是不是更新了课程
		async beforeclassDetail(item,sonitem){
			if(!sonitem.trainUserScheduleCourse){
				//判断一下，课程是不是变动，变动这个值会空，需要更新
				//s刷新课程
				var updateres = await this.updateUserAndSchedule()
			}
			//免费直接眺
			this.$router.push({
				path: "/classDetail",
				query:{
					uid:this.uid,
					chapterId:item.chapterId,
					courseId:sonitem.courseId
				}
			});
		},
		//s刷新课程
		updateUserAndSchedule(){
			var _this = this
			return new Promise((resolve, reject) => {
				var params = {
					scheduleId: _this.uid,
					userUuid: _this.userInfo.uuid,
				}
				_this.$http.post('TrainModule/train-user-schedule2/updateUserAndSchedule', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						resolve(res.data)
					} else {
						reject(false)
					}
				})
			})
		},
		//下标
		handleType(type){
			this.typeindex = type
		},
		//获取我目录详情
		getScheduleById(item,sonitem){
			var _this = this
			//需要登录
			if(this.isLogin){
				var params = {}
				params["scheduleId"] = this.uid
				params["userUuid"] = this.userInfo.uuid
				this.$http.post('TrainModule/train-user-schedule2/userGetScheduleProgress', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						if(res.data.schedule){
							_this.classObj = res.data.schedule
						}
						if(res.data.total&&res.data.total!=0){
							//进度
							if(res.data.success!=0){
								_this.progresstext = Number(((res.data.success / res.data.total)  * 100).toFixed(0));
							}
						}
						//根据分类推荐课程
						if(_this.tjclassList.length<=0){
							_this.gettjPageSchedule()
						}
						//判断是不是要启动弹出购买课程
						if(item&&sonitem){
							_this.handelclassdetail(item,sonitem)
						}else{
							//如果假如了学习，但是没购买
							_this.checkVipbuy()
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}
		},
		//获取我目录章节
		userGetScheduleChapter(){
			var _this = this
			//需要登录
			if(this.isLogin){
				var params = {
					currentPage:1,
					pageSize:888,
				}
				params["scheduleId"] = this.uid
				params["userUuid"] = this.userInfo.uuid
				this.$http.post('TrainModule/train-user-schedule2/userGetScheduleChapter', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						_this.chapterList = res.data
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}
		},
		//课程推荐
		gettjPageSchedule() {
			var _this = this
			var params = {}
			params["currentPage"] = 1
			params["pageSize"] = 12
			params["hot"] = 1 //0非精选 1精选
			params["typeCode"] = this.classObj.typeCodeList[0]
			this.$http.post('TrainModule/train-user-schedule2/getPageSchedule', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records
					_this.tjclassList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
		
	}
};
</script>
<style lang="scss" scoped></style>
